import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import "./projects-carousel.scss";

const ProjectsCarousel = ({ filteredProjects, handleProjectClick, scroll }) => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 40.625rem)",
  });

  // const isTabletOrDesktop = useMediaQuery({
  //   query: "(min-device-width: 40.625rem)",
  // });
  return (
    <>
      <button
        className="projects-carousel__scroll-button"
        onClick={() => scroll(-1)}
      >
        &#10094;
      </button>
      <ul id="projects-carousel--container">
        {filteredProjects.map(project => {
          const { title, shortDescription, imgUrl, horizontalImgUrl } = project;
          const mobileProjectImg = `url(${horizontalImgUrl})`;
          const desktopProjectImg = `url(${imgUrl})`;

          // const projectBackgroundImgUrl = isMobile
          //   ? mobileProjectImg
          //   : desktopProjectImg;

          const projectBackgroundImgUrl = isMobile
            ? mobileProjectImg
            : desktopProjectImg;
          return (
            <button
              key={title}
              style={{
                background: `none`,
                border: `none`,
                cursor: `pointer`,
                margin: `0`,
                textAlign: `left`,
                padding: `0`,
              }}
              onClick={() => handleProjectClick(project)}
            >
              <div
                className="helena-image-scroll"
                style={{
                  backgroundImage: projectBackgroundImgUrl,
                  // // backgroundImage: `url(/assets/${title.toLowerCase()}-vertical.png)`,
                  // backgroundSize: `cover`,
                  // // marginRight: `12px`,
                  // display: `flex`,
                  // flexDirection: `column`,
                  // justifyContent: `flex-end`,
                  // minWidth: `300px`,
                  // minHeight: `600px`,
                  // color: `#631d45`,
                  // padding: `0 1rem`,
                }}
              >
                <div className="projects-carousel__project--container">
                  <li>
                    <h2>{title}</h2>
                  </li>
                  <li>{shortDescription}</li>
                </div>
              </div>
            </button>
          );
        })}
      </ul>
      <button
        className="projects-carousel__scroll-button"
        onClick={() => scroll(1)}
      >
        &#10095;
      </button>
    </>
  );
};

ProjectsCarousel.propTypes = {
  filteredProjects: PropTypes.array.isRequired,
  handleProjectClick: PropTypes.func.isRequired,
  scroll: PropTypes.func.isRequired,
};

export default ProjectsCarousel;
