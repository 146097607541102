import React from "react";

import "./modal.scss";

const Modal = ({ show, handleClose, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    // TODO: MAKE MODAL ACCESSIBLE
    <div role="dialog" className={showHideClassName}>
      <section className="modal-main">
        <div className="modal--container">{children}</div>
        <button className="modal__button" onClick={handleClose}>
          x
        </button>
      </section>
    </div>
  );
};

export default Modal;
