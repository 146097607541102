import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./project-image-carousel.scss";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ProjectImageCarousel = ({ carouselImages }) => {
  return (
    <Slider {...settings}>
      {carouselImages.map(image => (
        <div className="image__container">
          <img alt={image.title} src={image.url} />
          {image.caption && <span>{image.caption}</span>}
        </div>
      ))}
    </Slider>
  );
};

ProjectImageCarousel.propTypes = {
  carouselImages: PropTypes.string.isRequired,
};

export default ProjectImageCarousel;
