import React from "react";

import { projects, projectFilterWords } from "../../constants";

import "./projects-filter-list.scss";

const ProjectsFilterList = ({ setFilteredProjects }) => {
  const filterProjects = (e, projects) => {
    const newProjects = projects.filter(project =>
      project.tags.includes(e.target.innerHTML.toLowerCase())
    );

    e.target.innerHTML === "All"
      ? setFilteredProjects(projects)
      : setFilteredProjects(newProjects);
  };

  return (
    <ul className="projects-filter-list">
      {projectFilterWords.map(projectFilterWord => {
        return (
          <li
            style={{ whiteSpace: `nowrap`, cursor: `pointer` }}
            key={projectFilterWord}
          >
            <button
              onClick={e => filterProjects(e, projects)}
              style={{
                background: `none`,
                border: `none`,
                padding: `none`,
                cursor: `pointer`,
              }}
            >
              {projectFilterWord}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default ProjectsFilterList;
