import inorganismsVerticalImgUrl from "./images/inorganisms-vertical.png";
import starWarsVerticalImgUrl from "./images/star-wars-vertical.png";
import kardiaVerticalImgUrl from "./images/kardia-vertical.png";
import yongoyvuqatsiVerticalImgUrl from "./images/yongoyvuqatsi-vertical.png";
import tasteSensationsVerticalImgUrl from "./images/taste-sensations-vertical.jpg";
import emergenceVerticalImgUrl from "./images/emergence-vertical.png";
import dissolveVerticalImgUrl from "./images/dissolve-vertical.png";
import whereWalworthEatsVerticalImgUrl from "./images/where-walworth-eats-vertical.png";
import movimientoVerticalImgUrl from "./images/movimiento-vertical.png";
import neonVerticalImgUrl from "./images/neon-vertical.png";
import barterBoxVerticalImgUrl from "./images/barter-box-vertical.png";
import gomaVerticalImgUrl from "./images/goma-vertical.png";
import callMeMummyVerticalImgUrl from "./images/call-me-mummy-vertical.png";
import senseOfDirectionVerticalImgUrl from "./images/sense-of-direction-vertical.png";
import equalityTechnologiesVerticalImgUrl from "./images/equality-technologies-vertical.png";
import voicesVerticalImgUrl from "./images/voices-vertical.png";
import soundbiteVerticalImgUrl from "./images/soundbite-vertical.png";
import artblabVerticalImgUrl from "./images/artblab-vertical.png";
import readingSystemsVerticalImgUrl from "./images/reading-systems-vertical.png";
import cunietunesVerticalImgUrl from "./images/cunietunes-vertical.png";
import designForAllVerticalImgUrl from "./images/design-for-all-vertical.png";
import loloVerticalImgUrl from "./images/lolo-vertical.png";
import deadSetVerticalImgUrl from "./images/dead-set-vertical.png";
import gramophronVerticalImgUrl from "./images/gramophron-vertical.png";
import smallTalksVerticalImgUrl from "./images/small-talks-vertical.png";
import arcanaVerticalImgUrl from "./images/arcana-vertical.png";
import wordCountsVerticalImgUrl from "./images/word-counts-vertical.png";
import universeInMicrocosmVerticalImgUrl from "./images/universe-in-microcosm-vertical.png";
import ymbbtVerticalImgUrl from "./images/ymbbt-vertical.png";
import modualVerticalImgUrl from "./images/modual-vertical.png";

import inorganismsHorizontalImgUrl from "./images/horizontal/inorganisms-lead.png";
import starWarsHorizontalImgUrl from "./images/horizontal/star-wars-lead.png";
import kardiaHorizontalImgUrl from "./images/horizontal/kardia-lead.jpg";
import yongoyvuqatsiHorizontalImgUrl from "./images/horizontal/yongoyvuqatsi-lead.png";
import tasteSensationsHorizontalImgUrl from "./images/horizontal/taste-sensations-lead.jpg";
import emergenceHorizontalImgUrl from "./images/horizontal/emergence-lead.png";
import dissolveHorizontalImgUrl from "./images/horizontal/dissolve-lead.png";
import whereWalworthEatsHorizontalImgUrl from "./images/horizontal/where-walworth-eats-lead.png";
import movimientoHorizontalImgUrl from "./images/horizontal/movimiento-lead.png";
import neonHorizontalImgUrl from "./images/horizontal/neon-lead.png";
import barterBoxHorizontalImgUrl from "./images/horizontal/barter-box-lead.png";
import gomaHorizontalImgUrl from "./images/horizontal/goma-lead.png";
import callMeMummyHorizontalImgUrl from "./images/horizontal/call-me-mummy-lead.png";
import senseOfDirectionHorizontalImgUrl from "./images/horizontal/sense-of-direction-lead.png";
import equalityTechnologiesHorizontalImgUrl from "./images/horizontal/equality-technologies-lead.png";
import voicesHorizontalImgUrl from "./images/horizontal/voices-lead.png";
import soundbiteHorizontalImgUrl from "./images/horizontal/soundbite-lead.png";
import artblabHorizontalImgUrl from "./images/horizontal/artblab-lead.png";
import readingSystemsHorizontalImgUrl from "./images/horizontal/reading-systems-lead.png";
import cunietunesHorizontalImgUrl from "./images/horizontal/cunietunes-lead.png";
import designForAllHorizontalImgUrl from "./images/horizontal/design-for-all-lead.png";
import loloHorizontalImgUrl from "./images/horizontal/lolo-lead.png";
import deadSetHorizontalImgUrl from "./images/horizontal/dead-set-lead.png";
import gramophronHorizontalImgUrl from "./images/horizontal/gramophron-lead.png";
import smallTalksHorizontalImgUrl from "./images/horizontal/small-talks-lead.png";
import arcanaHorizontalImgUrl from "./images/horizontal/arcana-lead.png";
import wordCountsHorizontalImgUrl from "./images/horizontal/word-counts-lead.png";
import universeInMicrocosmHorizontalImgUrl from "./images/horizontal/universe-in-microcosm-lead.png";
import ymbbtHorizontalImgUrl from "./images/horizontal/ymbbt-lead.png";
import modualHorizontalImgUrl from "./images/horizontal/modual-lead.png";

export const projectFilterWords = [
  "All",
  "Academic",
  "Animation",
  "Experience Design",
  "Fabrication",
  "Film",
  "Installation",
  "Interactive",
  "Project Management",
  "Public Speaking",
  "Speculative Design",
  "Theatre",
  "UX",
  "Workshops",
];

export const projects = [
  {
    title: "Inorganisms",
    longDescription: [
      "Displayed at London Design festival 2018, as part of Supra Systems Studio's show entitled Everything Happens So Much, Inorganisms: The Ecosystem is an immersive installation exploring object oriented ontology and the nature of human disruption. It is made up of a series of interactive kinetic sculptures: each sculpture has an output that affects the next sculpture’s behaviour, creating a chain reaction, leaving a self-sustaining ecosystem of inorganic organisms living in a state of symbiosis. Users are invited to investigate how their interaction with one or more sculptures can have an impact on the ecosystem as a whole. The sculptures are based on natural traits observed in the animal and plant kingdoms, investigating where the given affordances of everyday objects converge with organic behaviours. This ultimately forms a design fiction, aiming to provide an answer to the question: What if objects evolved to exist in the form that they do, rather than having been designed?",
    ],
    shortDescription: "Immersive Installation for London Design Festival",
    imgUrl: inorganismsVerticalImgUrl,
    horizontalImgUrl: inorganismsHorizontalImgUrl,
    videoUrl: ["375720950"],
    tags: ["experience design", "fabrication", "installation", "interactive"],
    // images: [
    //   {
    //     url: "https://picsum.photos/640/360",
    //     caption: "caption 1",
    //   },
    //   {
    //     url: "https://picsum.photos/640/360",
    //     caption: "caption 2",
    //   },
    //   {
    //     url: "https://picsum.photos/640/360",
    //     caption: "caption 3",
    //   },
    //   {
    //     url: "https://picsum.photos/640/360",
    //     caption: "caption 4",
    //   },
    // ],
  },
  {
    title: "Star Wars Episode IX",
    longDescription: [],
    shortDescription: "Building Spaceships in the Action Vehicles Department",
    imgUrl: starWarsVerticalImgUrl,
    horizontalImgUrl: starWarsHorizontalImgUrl,
    videoUrl: ["453791476"],
    tags: ["fabrication", "film"],
  },
  {
    title: "Kardia",
    longDescription: [
      "Kardia is a site-specific interactive installation created for the Science Gallery's season titled Blood: Life Uncut. It is an exploration of the internalisation and externalisation of the human heart beat, and comes in the form of a haptic chair. Users experience near complete sensory deprivation, with their only recognisable sensation becoming the feeling of their own heartbeat, beating through the chair.",
    ],
    shortDescription:
      "Site Specific Interactive Installation for Science Gallery London",
    imgUrl: kardiaVerticalImgUrl,
    horizontalImgUrl: kardiaHorizontalImgUrl,
    videoUrl: ["275402588"],
    tags: ["experience design", "fabrication", "installation", "interactive"],
  },
  {
    title: "Yongoyvuqatsi",
    longDescription: [],
    shortDescription: "A Super 8 Tone Poem Exploring the Climate Emergency",
    imgUrl: yongoyvuqatsiVerticalImgUrl,
    horizontalImgUrl: yongoyvuqatsiHorizontalImgUrl,
    videoUrl: ["453650693"],
    tags: ["film"],
  },
  {
    title: "Taste Sensations",
    longDescription: [
      "Taste sensations is an interactive eating experience in response to the Science Museum's brief regarding Food. It is an investigation into how different senses can affect perception of food. Taste sensations was exhibited at the Science Museum's Lates in January. It comes in the form of four miniature sensory experiences: miracle berries that make sour taste sweet, 3D printed fruit shaped candies that trick your ocular senses, a soundscape that makes crisps feel crunchier, and flavoured chocolates that all taste the same when your nose is plugged.",
    ],
    shortDescription: "Interactive Eating Experience for the Science Museum",
    imgUrl: tasteSensationsVerticalImgUrl,
    horizontalImgUrl: tasteSensationsHorizontalImgUrl,
    videoUrl: ["275402716"],
    tags: ["experience design", "interactive"],
  },
  {
    title: "EMERGENCE",
    longDescription: [],
    shortDescription:
      "Project Managing the London Design Festival for UAL’s LCC",
    imgUrl: emergenceVerticalImgUrl,
    horizontalImgUrl: emergenceHorizontalImgUrl,
    videoUrl: ["453783850"],
    tags: ["project management"],
  },
  {
    title: "Dissolve",
    longDescription: [],
    shortDescription:
      "Experimental Live Film/Theatre Project Exploring Memory Loss",
    imgUrl: dissolveVerticalImgUrl,
    horizontalImgUrl: dissolveHorizontalImgUrl,
    videoUrl: ["60123116"],
    tags: ["fabrication", "film", "theatre"],
  },
  {
    title: "Where Walworth Eats",
    longDescription: [],
    shortDescription:
      "Encouraging People in Elephant and Castle to Eat Healthily and Locally",
    imgUrl: whereWalworthEatsVerticalImgUrl,
    horizontalImgUrl: whereWalworthEatsHorizontalImgUrl,
    videoUrl: [""],
    tags: ["project management", "ux"],
  },
  {
    title: "Movimiento",
    longDescription: [
      "Featured as emerging talent at the Berlin Fashion Film Festival 2017, Movimiento explores belonging and the feeling of being both a little from everywhere and nowhere at all. In collaboration with stylist Amanda Michelena, it uses magic realism to create a dance between fantasies, realities, and worlds. Contains extracts from song 'Movimiento' by Jorge Drexler.",
    ],
    shortDescription: "Berlin Fashion Film Festival",
    imgUrl: movimientoVerticalImgUrl,
    horizontalImgUrl: movimientoHorizontalImgUrl,
    videoUrl: ["244074654"],
    tags: ["film"],
  },
  {
    title: "NEoN Re@ct Symposium",
    longDescription: [],
    shortDescription:
      "Discussing the Intersections of Disability, Design, and the Economy",
    imgUrl: neonVerticalImgUrl,
    horizontalImgUrl: neonHorizontalImgUrl,
    videoUrl: [""],
    tags: ["public speaking", "academic"],
  },
  {
    title: "Barter Box",
    longDescription: [],
    shortDescription:
      "Exploring Consumption Culture with a Short set in a Dystopian Future",
    imgUrl: barterBoxVerticalImgUrl,
    horizontalImgUrl: barterBoxHorizontalImgUrl,
    videoUrl: [""],
    tags: ["film", "fabrication"],
  },
  {
    title: "GOMA",
    longDescription: [],
    shortDescription:
      "Working as a Multimedia Designer at the Gallery of Modern Art, Brisbane",
    imgUrl: gomaVerticalImgUrl,
    horizontalImgUrl: gomaHorizontalImgUrl,
    videoUrl: [""],
    tags: ["animation", "experience design"],
  },
  {
    title: "Sense of Direction",
    longDescription: [],
    shortDescription: "Interactive Experience for Science Gallery London",
    imgUrl: senseOfDirectionVerticalImgUrl,
    horizontalImgUrl: senseOfDirectionHorizontalImgUrl,
    videoUrl: [""],
    tags: ["experience design", "interactive", "installation"],
  },
  {
    title: "Call Me Mummy",
    longDescription: [
      "Call Me Mummy is a short film that was written, produced, and edited within a 48 hour period as part of the 2017 48 Hour Film Project in London. It is the culmination of months of planning and producing, in collaboration with Lucio Martus and a crew of over 36 students and young professionals. The coming of age narrative is an exploration of the responsibility that comes with sensitive information, and is based on true stories known to various crew members. It follows a snippet of the life of Tina, a young woman who is forced to come to terms with the realisation that her mother is a sex worker.",
    ],
    shortDescription:
      "A Vibrant Coming of Age Short Covering the Responsibilities of Information",
    imgUrl: callMeMummyVerticalImgUrl,
    horizontalImgUrl: callMeMummyHorizontalImgUrl,
    videoUrl: ["239317339"],
    tags: ["film"],
  },
  {
    title: "Equality Technologies",
    longDescription: [
      "Equality technologies is a design fiction exploring relationships between gender and technology, finding tongue-in-cheek solutions to common experiences of everyday sexism. It was exhibited at the Women in Tech Leadership conference on 29th March, part of the Inclusive Tech Alliance. Equality Technologies  is based on theories outlined in the Xenofeminist manifesto, and is currently in the conceptual stage, awaiting funding to begin prototyping.",
    ],
    shortDescription:
      "Exploring the Relationship between Gender and Technology",
    imgUrl: equalityTechnologiesVerticalImgUrl,
    horizontalImgUrl: equalityTechnologiesHorizontalImgUrl,
    videoUrl: [""],
    tags: ["speculative design"],
  },
  {
    title: "Voices",
    longDescription: [],
    shortDescription: "Creative Workshops for Children on the JMB Estate",
    imgUrl: voicesVerticalImgUrl,
    horizontalImgUrl: voicesHorizontalImgUrl,
    videoUrl: ["452024361"],
    tags: ["workshops"],
  },
  {
    title: "Soundbite",
    longDescription: [],
    shortDescription: "A Quarantine Film about Love in Lockdown",
    imgUrl: soundbiteVerticalImgUrl,
    horizontalImgUrl: soundbiteHorizontalImgUrl,
    videoUrl: ["452023630"],
    tags: ["film"],
  },
  {
    title: "ArtBLAB",
    longDescription: [],
    shortDescription: "Discussing Creative Ecologies in the Modern World",
    imgUrl: artblabVerticalImgUrl,
    horizontalImgUrl: artblabHorizontalImgUrl,
    videoUrl: [""],
    tags: ["public speaking", "academic"],
  },
  {
    title: "Reading | Systems",
    longDescription: [],
    shortDescription:
      "Speaking on the Supra Systems Studio Panel at London Design Festival",
    imgUrl: readingSystemsVerticalImgUrl,
    horizontalImgUrl: readingSystemsHorizontalImgUrl,
    videoUrl: [""],
    tags: ["academic", "public speaking"],
  },
  {
    title: "Cuneitunes",
    longDescription: [],
    shortDescription: "A Guitar that Translates Music into Poetry",
    imgUrl: cunietunesVerticalImgUrl,
    horizontalImgUrl: cunietunesHorizontalImgUrl,
    videoUrl: ["452021163"],
    tags: ["fabrication", "interactive"],
  },
  {
    title: "How Can Design for ‘All’ be Commercially Viable?",
    longDescription: [],
    shortDescription: "BA Thesis",
    imgUrl: designForAllVerticalImgUrl,
    horizontalImgUrl: designForAllHorizontalImgUrl,
    videoUrl: [""],
    tags: ["academic"],
  },
  {
    title: "Lolo",
    longDescription: [],
    shortDescription:
      "A Reimagined Title Sequence for Julie Delpy’s Dark Comedy",
    imgUrl: loloVerticalImgUrl,
    horizontalImgUrl: loloHorizontalImgUrl,
    videoUrl: ["179164323"],
    tags: ["animation"],
  },
  {
    title: "Gramophron",
    longDescription: [],
    shortDescription: "Using Legacy Technology to Aid those with Altzheimers",
    imgUrl: gramophronVerticalImgUrl,
    horizontalImgUrl: gramophronHorizontalImgUrl,
    videoUrl: [""],
    tags: ["fabrication", "interactive"],
  },
  {
    title: "Dead Set",
    longDescription: [],
    shortDescription: "A Short Horror Film made in 48 Hours",
    imgUrl: deadSetVerticalImgUrl,
    horizontalImgUrl: deadSetHorizontalImgUrl,
    videoUrl: ["301086962"],
    tags: ["film"],
  },
  {
    title: "Small Talks",
    longDescription: [],
    shortDescription: "Talking about Family Mythologies",
    imgUrl: smallTalksVerticalImgUrl,
    horizontalImgUrl: smallTalksHorizontalImgUrl,
    videoUrl: [""],
    tags: ["public speaking"],
  },
  {
    title: "Arcana",
    longDescription: [],
    shortDescription: "Stop Motion Animation Bringing Tarot Cards to Life",
    imgUrl: arcanaVerticalImgUrl,
    horizontalImgUrl: arcanaHorizontalImgUrl,
    videoUrl: ["128676697"],
    tags: ["film", "animation"],
  },
  {
    title: "The Word Counts",
    longDescription: [],
    shortDescription:
      "Discussing the Intersections of Disability, Design, and the Economy",
    imgUrl: wordCountsVerticalImgUrl,
    horizontalImgUrl: wordCountsHorizontalImgUrl,
    videoUrl: [""],
    tags: ["public speaking", "academic"],
  },
  {
    title: "The Universe in Microcosm",
    longDescription: [],
    shortDescription: "Experimenting with Analogue Animation",
    imgUrl: universeInMicrocosmVerticalImgUrl,
    horizontalImgUrl: universeInMicrocosmHorizontalImgUrl,
    videoUrl: ["146596580"],
    tags: ["animation"],
  },
  {
    title: "YMBBT",
    longDescription: [],
    shortDescription: "Working on the West End Theatre Show",
    imgUrl: ymbbtVerticalImgUrl,
    horizontalImgUrl: ymbbtHorizontalImgUrl,
    videoUrl: [""],
    tags: ["theatre", "fabrication"],
  },
  {
    title: "ModUAL",
    longDescription: [],
    shortDescription: "Launching a Startup in 14 Days",
    imgUrl: modualVerticalImgUrl,
    horizontalImgUrl: modualHorizontalImgUrl,
    videoUrl: ["240802144", "234156888", "179163693"],
    //videoUrl: ["234156888"],
    //videoUrl: ["179163693"],
    tags: ["workshops"],
  },
];

export default { projectFilterWords, projects };
