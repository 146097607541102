import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
// import { Link } from "gatsby";

import Layout from "../components/Layout";
import VimeoEmbed from "../components/VimeoEmbed";
import SEO from "../components/Seo/";
import Modal from "../components/Modal";
import ProjectsCarousel from "../components/ProjectsCarousel";
import ProjectsFilterList from "../components/ProjectsFilterList";
import ProjectImageCarousel from "../components/ProjectImageCarousel";

import "./projects.scss";

import { projects } from "../constants";

const Projects = () => {
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectShowingInModal, setProjectShowingInModal] = useState("");
  const [projectShowing, setProjectShowing] = useState({});
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 40.62rem)",
  });

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const projectShowing = projects.find(projects => {
        return projects.title === projectShowingInModal;
      });
      setProjectShowing(projectShowing);
    }
  }, [projectShowingInModal]);

  const handleProjectClick = project => {
    setIsModalOpen(true);
    setProjectShowingInModal(project.title);
  };

  const scroll = direction => {
    let far =
      (document.getElementById("projects-carousel--container").offsetWidth /
        2) *
      direction;
    let pos = (document.getElementById(
      "projects-carousel--container"
    ).scrollLeft += far);
    document
      .getElementById("projects-carousel--container")
      .animate({ scrollLeft: pos }, 1000);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const handleToggleFilterButtonClick = () => {
    setFilterMenuOpen(!filterMenuOpen);
  };

  const renderProjectText = (projectText) => projectText.map(para => <p>{para}</p>);

  return (
    <Layout>
      <SEO title="Projects" />
      {isMobile ? (
        <>
          <div className="filter-button">
            <button
              className="filter-button__button"
              onClick={handleToggleFilterButtonClick}
            >
              Filter projects
            </button>
          </div>
          <div>{filterMenuOpen && <ProjectsFilterList />}</div>
          <ProjectsCarousel
            filteredProjects={filteredProjects}
            handleProjectClick={handleProjectClick}
            scroll={scroll}
          />
          <Modal show={isModalOpen} handleClose={hideModal}>
            {projectShowing && (
              <>
                <h1>{projectShowing.title}</h1>
                {projectShowing.videoUrl && (
                  <VimeoEmbed videoUrlNumbers={projectShowing.videoUrl} />
                )}
                {projectShowing.images && (
                  <ProjectImageCarousel
                    carouselImages={projectShowing.images}
                  />
                )}
                {projectShowing.longDescription && renderProjectText(projectShowing.longDescription)}
              </>
            )}
          </Modal>
        </>
      ) : (
        <>
          <div style={{ display: `flex` }}>
            <ProjectsFilterList setFilteredProjects={setFilteredProjects} />
            <ProjectsCarousel
              filteredProjects={filteredProjects}
              handleProjectClick={handleProjectClick}
              scroll={scroll}
            />
          </div>
          <Modal show={isModalOpen} handleClose={hideModal}>
            {projectShowing && (
              <>
                <h1>{projectShowing.title}</h1>
                <VimeoEmbed videoUrlNumbers={projectShowing.videoUrl} />
                {projectShowing.images && (
                  <ProjectImageCarousel
                    carouselImages={projectShowing.images}
                  />
                )}
                {projectShowing.longDescription && renderProjectText(projectShowing.longDescription)}
              </>
            )}
          </Modal>
        </>
      )}
      {/* <Link to="/">Go back to the homepage</Link> */}
    </Layout>
  );
};

export default React.memo(Projects);
