import React from "react";
import PropTypes from "prop-types";

import "./vimeo-embed.scss";

const VimeoEmbed = ({ videoUrlNumbers }) => {
  if (!videoUrlNumbers || videoUrlNumbers[0] === "") return null;
  return (
    <>
      {videoUrlNumbers &&
        videoUrlNumbers.map(videoUrlNumber => (
          <div key={videoUrlNumber} className="embed--container">
            <iframe
              title="vimeo"
              src={`https://player.vimeo.com/video/${videoUrlNumber}`}
              frameBorder="0"
              // allow="autoplay; fullscreen"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            ></iframe>
          </div>
        ))}
    </>
  );
};

VimeoEmbed.propTypes = {
  videoUrlNumbers: PropTypes.array.isRequired,
};

export default VimeoEmbed;
